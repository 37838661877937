import React, { useState, useEffect }  from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav, Container,  Offcanvas, Button } from "react-bootstrap";
import { NavLink } from 'react-router-dom';
import '../assets/css/headar.css'; // Import custom styles if needed
import logo from '../assets/img/logo.png';
import caret from '../assets/img/left-caret.svg';

const Header = () => {
    const [show, setShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Check screen width on mount and resize
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 992); // Adjust breakpoint as needed
    };

    checkMobile(); // Initial check
    window.addEventListener("resize", checkMobile);

    return () => window.removeEventListener("resize", checkMobile); // Cleanup
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (isMobile) {
      setShow(true);
    }
  };



    return (
        <Navbar  className="main-nav">
            <Container>
                <Navbar.Brand as={Link} to="/">
                    <img src={logo} alt="sunrise logo" className="logo" />
                </Navbar.Brand>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                 {/* Hamburger Button */}
                 <Nav className="ms-auto d-none d-lg-flex">
                        <Nav.Link as={NavLink} to="/">Home</Nav.Link>
                        <Nav.Link as={NavLink} to="/about">About</Nav.Link>
                        <Nav.Item className="dropdown">
                        <Nav.Link as={NavLink} to="/product">Product <img src={caret} alt="caret" className="icon-rotate" /></Nav.Link>
                        <ul className="submenu">
                            <li>
                                <Nav.Link as={NavLink} to="/product/deveg" className="dropdown-item">
                                    Dehydrated vegetables 
                                </Nav.Link>
                            </li>
                            <li>
                                <Nav.Link as={NavLink} to="/product/drifruit" className="dropdown-item">
                                    Fruit powder
                                </Nav.Link>
                            </li>
                        </ul>
                        </Nav.Item>
                        <Nav.Link as={NavLink} to="/gallery">Gallery</Nav.Link>
                        <Nav.Link as={NavLink} to="/blog">Blog</Nav.Link>
                        <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
                    </Nav>
                 {isMobile && (
                <Button  onClick={handleShow} className="hamburger-button">
                    ☰
                </Button>
                )}
                <Offcanvas show={show} onHide={handleClose} placement="start" className="custom-offcanvas">
                <Offcanvas.Header>
                    <Offcanvas.Title><img src={logo} alt="sunrise logo" className="logo" /></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="ms-auto">
                        <Nav.Link as={NavLink} to="/">Home</Nav.Link>
                        <Nav.Link as={NavLink} to="/about">About</Nav.Link>
                        <Nav.Item className="dropdown">
                        <Nav.Link as={NavLink} to="/product">Product <img src={caret} alt="caret" className="icon-rotate" /></Nav.Link>
                        <ul className="submenu">
                            <li>
                                <Nav.Link as={NavLink} to="/product/deveg" className="dropdown-item">
                                    Dehydrated vegetables 
                                </Nav.Link>
                            </li>
                            <li>
                                <Nav.Link as={NavLink} to="/product/drifruit" className="dropdown-item">
                                    Fruit powder
                                </Nav.Link>
                            </li>
                        </ul>
                        </Nav.Item>
                        <Nav.Link as={NavLink} to="/gallery">Gallery</Nav.Link>
                        <Nav.Link as={NavLink} to="/blog">Blog</Nav.Link>
                        <Nav.Link as={NavLink} to="/contact">Contact</Nav.Link>
                    </Nav>
                    </Offcanvas.Body>
                </Offcanvas>
            </Container>
        </Navbar>
    );
}

export default Header;
