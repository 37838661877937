import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import icon from '../assets/img/check.svg';

function About() {
    return (
        <div>
        <section className="welcome-message section-padding">
        <Container>
            <Row className="">
            <Col sm={12}>
            <div className="d-flex flex-column infor-di mb-5">
              <h2 className="title">About Us: Sunrise Agri Products
              </h2>
            </div>
            </Col>
            </Row>
          <Row className="align-items-center about-us">
            {/* Column 1: Image */}
            <Col md={4} sm={12}>
              
              <img src={require('../assets/img/ab-img.jpg')} alt="Sunrise logo" className='img-fluid rounded' />
            </Col>
  
            {/* Column 2: Information */}
            <Col md={8} sm={12}>
            <div className="d-flex flex-column infor-di">
              
              <p>
              At Sunrise Agri Products, we believe in transforming the natural bounty of fruits and vegetables into
                convenient, high-quality dehydrated forms that cater to the needs of modern consumers. From granules
                to powders to flakes, we specialize in delivering products that combine nutrition, taste, and reliability to
                a global market. 
              </p>
              <h4 className="subtitle">Company History</h4>
              <p>Sunrise Agri Products began in 2018 with a passion for agriculture and a mission to make wholesome,
natural foods more accessible. Inspired by the vast potential of India’s fertile lands and the growing
global demand for sustainable food solutions, our founders embarked on a journey to create a company
rooted in innovation and quality. </p>
<p>What started as a small venture quickly grew into an internationally recognized business, thanks to our
commitment to superior products and ethical practices. Over the years, we have expanded our
processing capabilities, embraced cutting-edge dehydration technology, and established strong
partnerships with local farmers to source the freshest raw materials. Today, we proudly export our
dehydrated fruits and vegetables to clients across continents, from food manufacturers to culinary
professionals. </p>

              
              </div>
            </Col>
          </Row>
          <Row className="mt-5">
          <Col md={6} sm={12}>
            <div className="d-flex flex-column infor-di mi-gray">
              <h2 className="title">Vision Statement</h2>
              <p>
              To be a global leader in the dehydrated food industry by delivering products that inspire trust, promote
health, and contribute to a sustainable future. We envision a world where quality, innovation, and
environmental stewardship go hand in hand to meet the growing demands of a dynamic food industry. 

              </p>
              </div>
            </Col>
            <Col md={6} sm={12}>
            <div className="d-flex flex-column infor-di mi-gray">
              <h2 className="title">Mission Statement</h2>
              <p>
              Our mission is to enrich lives by offering premium dehydrated fruits and vegetables that exceed
              customer expectations in quality, taste, and sustainability. We are dedicated to: 
              </p>
              <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Partnering with farmers to cultivate and source the finest produce. </li>
                <li><img src={icon} alt="icon" className="" />Employing environmentally conscious practices in every aspect of our operations. </li>
                <li><img src={icon} alt="icon" className="" />Maintaining the highest standards of hygiene and food safety.</li>
                <li><img src={icon} alt="icon" className="" />Building lasting relationships with our customers through exceptional service and reliability. </li>
              </ul>
              <p>
              At Sunrise Agri Products, we aim to redefine what it means to deliver quality and sustainability, one
                product at a time. 
              </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product-tab section-padding">
      <Container>
        <Row>
          <Col md={6} sm={12} className="mx-auto">
            <h3>We invite you to join us on our journey toward a brighter, healthier future.</h3>
          </Col>
          
        </Row>
      </Container>
    </section>
    </div>
    );
}

export default About;
