import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import img1 from '../assets/img/product/f3.jpg';
import img2 from '../assets/img/product/f2.jpg';
import img4 from '../assets/img/product/f4.jpg';
import img5 from '../assets/img/product/f5.jpg';
import img6 from '../assets/img/product/f6.jpg';
import img7 from '../assets/img/product/f7.jpg';
import img8 from '../assets/img/product/f8.jpg';
import img9 from '../assets/img/product/f9.jpg';
import img10 from '../assets/img/product/f10.jpg';
import img11 from '../assets/img/product/f11.jpg';
import img12 from '../assets/img/product/f12.jpg';

const GalleryPage = () => {
  const images = [
    img1, img2, img4, img5, img6, img7, img8, img9, img10, img11, img12,
  ];

  const [show, setShow] = useState(false);
  const [currentImg, setCurrentImg] = useState('');

  const handleShow = (img) => {
    setCurrentImg(img);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <section className="gallery-page section-padding">
      <div className="container">
        <div className="row">
          {images.map((img, index) => (
            <div key={index} className="col-4 col-sm-3 col-md-2 mb-4">
              <img
                src={img}
                alt={`Gallery ${index + 1}`}
                className="img-fluid gallery-image"
                onClick={() => handleShow(img)}
              />
            </div>
          ))}
        </div>

        {/* Modal */}
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body>
            <img src={currentImg} alt="Lightbox" className="img-fluid" />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </section>
  );
};

export default GalleryPage;
