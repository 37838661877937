import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import '../assets/css/Button.css';
import ButtonLink from '../components/ButtonLink';
import '../assets/css/Blog.css'; // Optional: For custom styles
import blog1 from '../assets/img/blog/blog1.jpg';
import blog2 from '../assets/img/blog/blog2.jpg';
import blog3 from '../assets/img/blog/blog3.jpg';

const Blog = () => {
  // Example data for the blog posts
  const blogPosts = [
    {
      id: 1,
      title: 'Post 1 Title',
      description: 'This is the description for the first blog post. It gives a brief overview of the content.',
      tag: "food",
      imgSrc: blog1, // Assign the imported image directly
      link: "/blog", // External link to the blog post
    },
    {
      id: 2,
      title: 'Post 2 Title',
      description: 'This is the description for the second blog post. It provides insights into the content of the post.',
      tag: "Technology",
      imgSrc: blog2, // Assign the imported image directly
      link: "/blog", // External link to the blog post
    },
    {
      id: 3,
      title: 'Post 3 Title',
      description: 'Here is the description for the third blog post. It highlights the key points of the article.',
      tag: "Technology",
      imgSrc: blog3, // Assign the imported image directly
      link: "/blog", // External link to the blog post
    },
  ];

  return (
    <Container className="section-padding blog">
      <Row>
        {blogPosts.map((post) => (
          <Col key={post.id} md={4} sm={6} className="mb-4">
            <Card>
             
              <div className="img-wrapp">
              <Card.Img
                variant="top"
                src={post.imgSrc} // Dynamically require the image
                alt={post.title}
              />
              <div className="tag">{post.tag}</div> {/* Tag Section */}
             </div>
              <Card.Body>
                <Card.Title>{post.title}</Card.Title>
                <Card.Text>{post.description}</Card.Text>
                
            
            <ButtonLink link={post.link} name="Read more" />
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Blog;
