import React from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import icon from '../assets/img/check.svg';

function Dehydratedvegetables() {
    return (
        <div>
        <section className="welcome-message section-padding">
        <Container>
            <Row className="">
            <Col md={6} >
            <div className="mi-gray">
            <div className="d-flex flex-column infor-di mb-3">
              <h2 className="title font-22">Dehydrated Onion
              </h2>
            </div>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={4}>
            <img src={require('../assets/img/product/p5.jpg')} alt="product" className='img-fluid' />
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={8}>
            <div className="d-flex flex-column infor-di">
              
              <p>
              Dehydrated onions offer a perfect balance of tartness, sweetness, and concentrated flavor,
              enhanced by a distinct, pungent aroma. A small quantity of dehydrated onions can be rehydrated
              to yield significant amounts, making them a time-saving alternative to fresh onions for quick
              meals and instant cooking.
              </p>
              
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} sm={12} className="mt-3">
          <div className="d-flex flex-column infor-di">
          <div className="tab-section">
        <Tabs defaultActiveKey="oniaon" id="product-tabs" className="mb-3 ">
          <Tab eventKey="oniaon" title="Key Features" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Easily resized and granulated for diverse culinary uses. </li>
                <li><img src={icon} alt="icon" className="" />A versatile pairing with dehydrated garlic and ginger. </li>
                <li><img src={icon} alt="icon" className="" />Convenient and long-lasting compared to fresh onions.</li>
          </ul>
            
          </Tab>
          <Tab eventKey="oniaon1" title="Applications" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Pickles:</strong> Adds a spicy twist to enhance the taste.</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Seasonings:</strong> Perfect for seasoning crispy chips and noodles with a new, delightful flavor.</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>RTE/Masala Powders:</strong> Imparts a natural, aromatic onion flavor.</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Ayurveda:</strong> Used to support circulation and digestion in traditional remedies.</p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Culinary Uses:</strong> Adds rich onion flavor to various dishes without the hassle of peeling and chopping fresh onions.</p></li>
          </ul>
           
          </Tab>
        </Tabs>
        </div>
          </div>
          </Col>
          </Row>
          </div>
          </Col>

          <Col md={6} >
          <div className="mi-gray">
            <div className="d-flex flex-column infor-di mb-3">
              <h2 className="title font-22">Dehydrated Garlic
              </h2>
            </div>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={4}>
            <img src={require('../assets/img/product/p3.jpg')} alt="product" className='img-fluid' />
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={8}>
            <div className="d-flex flex-column infor-di">
             <p>
              Our dehydrated garlic is available in diverse forms, including powder, flakes, granules, minced,
chopped, toasted, fried, and roasted. It features an exceptional creamy-white color and a
concentrated flavor profile.
              </p>
              
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} sm={12} className="mt-3">
          <div className="d-flex flex-column infor-di">
          <div className="tab-section">
        <Tabs defaultActiveKey="Garlic" id="product-tabs" className="mb-3 ">
        <Tab eventKey="Garlic" title="Available Sizes" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Granules:</strong> 0.5mm–1mm</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Minced:</strong>  1mm–3mm</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>Chopped:</strong> 3mm–5mm</p></li>
          </ul>
           
          </Tab>
          <Tab eventKey="Garlic1" title="Key Features" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Retains fresh garlic flavor in a dehydrated form. </li>
                <li><img src={icon} alt="icon" className="" />Affordable, compact, and easy to use without peeling.</li>
                <li><img src={icon} alt="icon" className="" />Customizable sizes and forms to suit customer needs.</li>
          </ul>
            
          </Tab>
          <Tab eventKey="Garlic2" title="Applications" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Pickles:</strong> Elevates taste with a distinctive garlic twist.</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Masala:</strong> Enhances flavor in spice blends and seasonings.</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>RTE Products:</strong> Blends seamlessly into ready-to-eat meals.</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Snacks:</strong> Adds flavor to noodles, gravies, and sauces.</p></li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Pharmaceuticals:</strong> Used for its therapeutic properties in healthcare products.</p></li>
          </ul>
           
          </Tab>
          
        </Tabs>
        </div>
          </div>
          </Col>
          </Row>
          </div>
          </Col>

          <Col md={6} >
          <div className="mi-gray">
            <div className="d-flex flex-column infor-di mb-3">
              <h2 className="title font-22">Dehydrated Ginger
              </h2>
            </div>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={4}>
            <img src={require('../assets/img/product/p6.jpg')} alt="product" className='img-fluid' />
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={8}>
            <div className="d-flex flex-column infor-di">
             <p>
             Dehydrated ginger is highly pungent, adding heat to dishes while offering numerous medicinal
benefits. It is processed into flakes and powder with a distinct aroma and flavor, making it a
sought-after ingredient worldwide.
              </p>
              
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} sm={12} className="mt-3">
          <div className="d-flex flex-column infor-di">
          <div className="tab-section">
        <Tabs defaultActiveKey="Ginger" id="product-tabs" className="mb-3 ">
        <Tab eventKey="Ginger" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Contains gingerol, a compound with powerful medicinal properties.</li>
                <li><img src={icon} alt="icon" className="" />Supports digestion, improves circulation, and boosts immunity.</li>
                <li><img src={icon} alt="icon" className="" />Alleviates symptoms of arthritis, nausea, menstrual pain, and respiratory issues.</li>
          </ul>
           
          </Tab>
          <Tab eventKey="Ginger1" title="Key Features" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Free from preservatives and artificial colors.</li>
                <li><img src={icon} alt="icon" className="" />Hygienically packaged to retain freshness.</li>
                <li><img src={icon} alt="icon" className="" />Available in irregular shapes and fine powders.</li>
          </ul>
            
          </Tab>
          <Tab eventKey="GGinger2" title="Applications" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Culinary: </strong> Used in sauces, marinades, and baked goods.</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Medicinal:</strong> Enhances herbal remedies and cough syrups.</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>Versatile Ingredient:</strong> Adds unique flavor to soups, snacks, and beverages.</p></li>
          </ul>
           
          </Tab>
          
        </Tabs>
        </div>
          </div>
          </Col>
          </Row>
          </div>
          </Col>

          <Col md={6} >
          <div className="mi-gray">
            <div className="d-flex flex-column infor-di mb-3">
              <h2 className="title font-22">Dehydrated Mixed Vegetables
              </h2>
            </div>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={4}>
            <img src={require('../assets/img/product/p4.jpg')} alt="product" className='img-fluid' />
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={8}>
            <div className="d-flex flex-column infor-di">
             <p>
             A vibrant blend of dehydrated carrots, red onions, cabbage, red bell peppers, tomatoes, and garlic
offers convenience and versatility for various culinary needs.
              </p>
              
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} sm={12} className="mt-3">
          <div className="d-flex flex-column infor-di">
          <div className="tab-section">
        <Tabs defaultActiveKey="Mixveg" id="product-tabs" className="mb-3 ">
        <Tab eventKey="Mixveg" title="Available Forms" className="mb-3 ">
        <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Flakes, minced, chopped, granules, and bits.</li>
                <li><img src={icon} alt="icon" className="" /><p><strong>Sizes: </strong> 1–3mm and customized options.</p></li>
          </ul>
           
          </Tab>
        
          <Tab eventKey="Mixveg2" title="Applications" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Noodles: </strong> Enhances instant noodles with a healthy vegetable boost.</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Soups:</strong> Adds rich flavor and nutrients to instant soup mixes.</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>Snacks:</strong> Elevates the taste of biscuits and fast foods.</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>Premixes:</strong> A perfect combination of taste and health for instant food premixes.</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>Canned Foods:</strong> Improves the nutritional value and flavor of preserved foods.</p></li>
          </ul>
           
          </Tab>
        </Tabs>
        </div>
          </div>
          </Col>
          </Row>
          </div>
          </Col>

          <Col md={6} >
          <div className="mi-gray">
            <div className="d-flex flex-column infor-di mb-3">
              <h2 className="title font-22">Dehydrated Tomato
              </h2>
            </div>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={4}>
            <img src={require('../assets/img/product/p10.jpg')} alt="product" className='img-fluid' />
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={8}>
            <div className="d-flex flex-column infor-di">
             <p>
             Our dehydrated tomato products are made from high-grade tomatoes, purified under hygienic
conditions, ensuring excellent taste and health benefits. Known for their rich potassium content
and skin-purifying properties, these tomatoes are free from impurities and side effects, making
them ideal for culinary and medicinal uses.


              </p>
              
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} sm={12} className="mt-3">
          <div className="d-flex flex-column infor-di">
          <div className="tab-section">
        <Tabs defaultActiveKey="tomato" id="product-tabs" className="mb-3 ">
        <Tab eventKey="tomato" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Rich in antioxidants like lycopene, reducing the risk of heart disease and cancer.</li>
                <li><img src={icon} alt="icon" className="" />Excellent source of vitamin C, potassium, folate, and fiber.</li>
                <li><img src={icon} alt="icon" className="" />Improves skin health and supports cardiovascular functions.
                </li>
                <li><img src={icon} alt="icon" className="" />Hydrates the body with its 95% water content.
                </li>
                <li><img src={icon} alt="icon" className="" />Promotes overall well-being with its vitamin B complex and magnesium
                </li>
          </ul>
           
          </Tab>
          <Tab eventKey="tomato1" title="Key Features" className="mb-3 ">
          <ul className="listname">
          <h4 className="subtitle mb-2">Culinary Uses:</h4>
                <li>
                <img src={icon} alt="icon" className="" />Pizza toppings and sautéed vegetables for enhanced flavor.</li>
                <li><img src={icon} alt="icon" className="" />Thickening soups, stews, and sauces with intensified tomato taste.
                </li>
                <li><img src={icon} alt="icon" className="" />Base ingredient for curries, sauces, and other dishes.
                </li>
          <h4 className="subtitle mb-2">Other Uses:</h4>
          <li>
                <img src={icon} alt="icon" className="" />Flavor enhancement and food coloring.
                </li>
                <li><img src={icon} alt="icon" className="" />Suitable for ready-to-eat (RTE) products and seasonings.
                </li>
          </ul>
            
          </Tab>
        </Tabs>
        </div>
          </div>
          </Col>
          </Row>
          </div>
          </Col>


          <Col md={6} >
          <div className="mi-gray">
            <div className="d-flex flex-column infor-di mb-3">
              <h2 className="title font-22">Dehydrated Cabbage
              </h2>
            </div>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={4}>
            <img src={require('../assets/img/product/p11.jpg')} alt="product" className='img-fluid' />
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={8}>
            <div className="d-flex flex-column infor-di">
             <p>
             Dehydrated cabbage flakes and powder are crafted from premium-quality cabbages.
Hygienically processed, these products retain freshness and are free from harmful additives.
They are convenient, lightweight, and perfect for ethnic food preparations, soups, and fast foods.
              </p>
              
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} sm={12} className="mt-3">
          <div className="d-flex flex-column infor-di">
          <div className="tab-section">
        <Tabs defaultActiveKey="tomato" id="product-tabs" className="mb-3 ">
        <Tab eventKey="tomato" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Packed with vitamins A, C, and K, along with iron and riboflavin.
                </li>
                <li><img src={icon} alt="icon" className="" />Promotes heart health and supports vision with its antioxidant properties.</li>
                <li><img src={icon} alt="icon" className="" />Reduces inflammation and boosts the immune system.
                </li>
                <li><img src={icon} alt="icon" className="" />Aids in digestion with its soluble fiber content.
                </li>
                <li><img src={icon} alt="icon" className="" />Supports bone health and helps lower the risk of certain diseases.
                </li>
          </ul>
           
          </Tab>
          <Tab eventKey="tomato1" title="Applications" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Soups and stews</li>
                <li><img src={icon} alt="icon" className="" />Salads and casserole mixes.
                </li>
                <li><img src={icon} alt="icon" className="" />Frozen or canned vegetable blends.
                </li>
                <li><img src={icon} alt="icon" className="" />Dry soup mixes and sauces.
                </li>
          </ul>
            
          </Tab>
        </Tabs>
        </div>
          </div>
          </Col>
          </Row>
          </div>
          </Col>


            </Row>
          
        </Container>
      </section>
      <section className="product-tab section-padding">
      <Container>
        <Row>
          <Col lg={6} sm={12} className="mx-auto">
            <h3>We invite you to join us on our journey toward a brighter, healthier future.</h3>
          </Col>
          
        </Row>
      </Container>
    </section>
    </div>
    );
}

export default Dehydratedvegetables;
