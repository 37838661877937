import React, { useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    subject: '',
    message: '',
  });

  const [formStatus, setFormStatus] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Form validation (optional)
    if (!formData.name || !formData.email || !formData.message) {
      setFormStatus('Please fill in all required fields.');
      return;
    }

    // Send form data to the server or email service
    console.log('Form Data:', formData);
    setFormStatus('Thank you for your inquiry. We will get back to you soon!');
    setFormData({ name: '', email: '', mobile: '', subject: '', message: '' });
  };

  return (

    <Container className="contact-form section-padding">
        <Row>
            <Col lg={6} sm={12} className='mx-auto'>
      {formStatus && <p className="form-status">{formStatus}</p>}
      <form onSubmit={handleSubmit} className='d-flex flex-column infor-di mi-gray'>
      <h4 className="subtitle">"We’d love to hear from you!
      </h4>
              <h2 className="title">WRITE A MESSAGE
              </h2>
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input
            type="text"
            id="name"
            name="name"
            className="form-control"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="mobile">Mobile Number *</label>
          <input
            type="tel"
            id="mobile"
            name="mobile"
            className="form-control"
            value={formData.mobile}
            onChange={handleChange}
            pattern="[0-9]{10}" // Optional validation for 10-digit number
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <input
            type="email"
            id="email"
            name="email"
            className="form-control"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="subject">Subject</label>
          <input
            type="text"
            id="subject"
            name="subject"
            className="form-control"
            value={formData.subject}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="message">Message *</label>
          <textarea
            id="message"
            name="message"
            className="form-control"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="theme-btn mx-auto">
          Submit
        </button>
      </form>
      </Col>
      </Row>
    </Container>
  );
};

export default ContactForm;
