import React from 'react';
import { Carousel } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is loaded
import hero1 from '../assets/img/hero1.jpg';
import hero2 from '../assets/img/hero2.jpg';
import hero3 from '../assets/img/hero3.jpg';

const HeroSection = () => {
  const products = [
    {
      id: 1,
      img: hero1,
      title: 'Dehydrated Onion',
    },
    {
      id: 2,
      img: hero2,
      title: 'Mango Powder',
    },
    {
      id: 3,
      img: hero3,
      title: 'Dehydrated Garlic',
    },
  ];

  return (
    
      <Carousel fade interval={3000} className='hero-img'>
        {products.map((product) => (
          <Carousel.Item key={product.id}>
            <img
              className="d-block w-100"
              src={product.img}
              alt={product.title}
            />
            <Carousel.Caption>
              <h3>{product.title}</h3>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
  );
};

export default HeroSection;
