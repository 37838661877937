import React from "react";

const WhatsAppButton = () => {
  return (
    <a
      href="https://wa.me/919359731355?text=Hello%20I%20Sir!" // Replace with your number
      target="_blank"
      rel="noopener noreferrer"
      className="whatsapp-fixed-button"
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg"
        alt="WhatsApp"
        className="whatsapp-icon"
      />
    </a>
  );
};

export default WhatsAppButton;
