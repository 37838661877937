import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import blog1 from '../assets/img/blog/blog1.jpg';
import blog2 from '../assets/img/blog/blog2.jpg';
import blog3 from '../assets/img/blog/blog3.jpg';

function Blog() {
    return (
        <section className="welcome-message section-padding blog-page">
        <Container>
            <Row className="">
            <Col sm={12}>
            <div className="d-flex flex-column infor-di mb-5">
              <h2 className="title">Our Latest Blog Posts
              </h2>
            </div>
            </Col>
            </Row>
          <Row className="blog-details">
            {/* Column 1: Image */}
            <Col md={4} sm={12}>
                <img src={blog1} alt="icon" className="img-fluid rounded" />
            </Col>
  
            {/* Column 2: Information */}
            <Col md={8} sm={12}>
            <div className="d-flex flex-column infor-di blog">
            <div class="card-title h5">Post 2 Title</div>
              <p>
              At Sunrise Agri Products, we believe in transforming the natural bounty of fruits and vegetables into
                convenient, high-quality dehydrated forms that cater to the needs of modern consumers. From granules
                to powders to flakes, we specialize in delivering products that combine nutrition, taste, and reliability to
                a global market. 
              </p>
              <p>Sunrise Agri Products began in 2008 with a passion for agriculture and a mission to make wholesome,
natural foods more accessible. Inspired by the vast potential of India’s fertile lands and the growing
global demand for sustainable food solutions, our founders embarked on a journey to create a company
rooted in innovation and quality. </p>


              
              </div>
            </Col>
          </Row>
          <Row className="blog-details">
            {/* Column 1: Image */}
            <Col md={4} sm={12}>
                <img src={blog2} alt="icon" className="img-fluid rounded" />
            </Col>
  
            {/* Column 2: Information */}
            <Col md={8} sm={12}>
            <div className="d-flex flex-column infor-di blog">
            <div class="card-title h5">Post 2 Title</div>
              <p>
              At Sunrise Agri Products, we believe in transforming the natural bounty of fruits and vegetables into
                convenient, high-quality dehydrated forms that cater to the needs of modern consumers. From granules
                to powders to flakes, we specialize in delivering products that combine nutrition, taste, and reliability to
                a global market. 
              </p>
              <p>Sunrise Agri Products began in 2008 with a passion for agriculture and a mission to make wholesome,
natural foods more accessible. Inspired by the vast potential of India’s fertile lands and the growing
global demand for sustainable food solutions, our founders embarked on a journey to create a company
rooted in innovation and quality. </p>


              
              </div>
            </Col>
          </Row>
          <Row className="blog-details">
            {/* Column 1: Image */}
            <Col md={4} sm={12}>
                <img src={blog3} alt="icon" className="img-fluid rounded" />
            </Col>
  
            {/* Column 2: Information */}
            <Col md={8} sm={12}>
            <div className="d-flex flex-column infor-di blog">
            <div class="card-title h5">Post 2 Title</div>
              <p>
              At Sunrise Agri Products, we believe in transforming the natural bounty of fruits and vegetables into
                convenient, high-quality dehydrated forms that cater to the needs of modern consumers. From granules
                to powders to flakes, we specialize in delivering products that combine nutrition, taste, and reliability to
                a global market. 
              </p>
              <p>Sunrise Agri Products began in 2008 with a passion for agriculture and a mission to make wholesome,
natural foods more accessible. Inspired by the vast potential of India’s fertile lands and the growing
global demand for sustainable food solutions, our founders embarked on a journey to create a company
rooted in innovation and quality. </p>


              
              </div>
            </Col>
          </Row>
          </Container>
          </section>
    );
}

export default Blog;
