import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageBanner from './components/PageBanner';
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Product from "./pages/Product";
import Gallery from "./pages/Gallery";
import Blog from "./pages/Blog";
import Contact from "./pages/Contact";
import Drifruit from "./pages/Drifruit";
import Deveg from "./pages/Deveg";

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/about" element={<><PageBanner /><About /></>} />
                <Route path="/product" element={<><PageBanner /><Product /></>} />
                <Route path="/product/deveg" element={<><PageBanner /><Deveg /></>} />
                <Route path="/product/drifruit" element={<><PageBanner /><Drifruit /></>} />
                <Route path="/gallery" element={<><PageBanner /><Gallery /></>} />
                <Route path="/blog" element={<><PageBanner /><Blog /></>} />
                <Route path="/contact" element={<><PageBanner /><Contact /></>} />
                
            </Routes>
            <Footer />
        </Router>
    );
}



export default App;
