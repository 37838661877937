import React from 'react';
import '../assets/css/Button.css';
import caret from '../assets/img/left-caret.svg';


const ButtonLink = ({ link, name, target = "_blank", className = "theme-btn" }) => {
  return (
    <a href={link} target={target} rel="noopener noreferrer" className={className}>
      {name}
      <img src={caret} alt="caret" className="icon" />
    </a>
  );
};

export default ButtonLink;
