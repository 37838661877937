import React from "react";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";
import icon from '../assets/img/check.svg';

function DriedFruit() {
  return (
      <div>
      <section className="welcome-message section-padding">
      <Container>
          <Row className="">
          <Col md={6} >
          <div className="mi-gray">
          <div className="d-flex flex-column infor-di mb-3">
            <h2 className="title font-22">Spray-Dried Mango Powder
            </h2>
          </div>
        <Row className="align-items-center ">
          {/* Column 1: Image */}
          <Col lg={4}>
          <img src={require('../assets/img/product/p1.jpg')} alt="product" className='img-fluid' />
          </Col>

          {/* Column 2: Information */}
          <Col lg={8}>
          <div className="d-flex flex-column infor-di">
            
            <p>
            Our mango powder captures the essence of fresh mangoes, providing a natural flavoring and
coloring agent. Available in alphonso and totapuri varieties, it is a versatile ingredient for food,
beverages, and nutraceuticals.

            </p>
            
            </div>
          </Col>
        </Row>
        <Row>
        <Col md={12} sm={12} className="mt-3">
        <div className="d-flex flex-column infor-di">
        <div className="tab-section">
      <Tabs defaultActiveKey="Mango" id="product-tabs" className="mb-3 ">
        <Tab eventKey="Mango" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />High in vitamins A, C, E, and antioxidants like Mangifera.</li>
              <li><img src={icon} alt="icon" className="" />Boosts immunity and supports heart health. </li>
              <li><img src={icon} alt="icon" className="" />Promotes healthy digestion and metabolism.</li>
              <li><img src={icon} alt="icon" className="" />Rich in folate and potassium for overall well-being.</li>
        </ul>
          
        </Tab>
        <Tab eventKey="mango1" title="Applications" className="mb-3 ">
        <ul className="listname">
        <h4 class="subtitle mb-2">Food & Beverage:</h4>
              <li>
              <img src={icon} alt="icon" className="" />Flavored drinks, sweets, and confectionery.</li>
              <li><img src={icon} alt="icon" className="" />Instant mixes and health supplements.</li>
              <li><img src={icon} alt="icon" className="" />Baby foods and syrups.</li>
        <h4 class="subtitle mb-2">Other Uses:</h4>
              <li>
              <img src={icon} alt="icon" className="" />Natural sweetener for various recipes.</li>
              <li><img src={icon} alt="icon" className="" />Adds a tangy twist to salads and marinades.</li>
              
        </ul>
         
        </Tab>
      </Tabs>
      </div>
        </div>
        </Col>
        </Row>
        </div>
        </Col>

        <Col md={6} >
        <div className="mi-gray">
          <div className="d-flex flex-column infor-di mb-3">
            <h2 className="title font-22">Spray-Dried Banana Powder
            </h2>
          </div>
        <Row className="align-items-center ">
          {/* Column 1: Image */}
          <Col lg={4}>
          <img src={require('../assets/img/product/f4.jpg')} alt="product" className='img-fluid' />
          </Col>

          {/* Column 2: Information */}
          <Col lg={8}>
          <div className="d-flex flex-column infor-di">
           <p>
           Our banana powder is a natural flavor enhancer and sweetening agent, preserving the nutritional
profile of fresh bananas. It dissolves quickly, making it ideal for beverages, health mixes, and
baby foods.

            </p>
            
            </div>
          </Col>
        </Row>
        <Row>
        <Col md={12} sm={12} className="mt-3">
        <div className="d-flex flex-column infor-di">
        <div className="tab-section">
      <Tabs defaultActiveKey="Banana" id="product-tabs" className="mb-3 ">
        <Tab eventKey="Banana" title="Applications" className="mb-3 ">
        <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />Flavored milk and beverages. </li>
              <li><img src={icon} alt="icon" className="" />Health supplements and nutraceuticals.</li>
              <li><img src={icon} alt="icon" className="" />Sweets, confectionery, and energy bars.</li>
              <li><img src={icon} alt="icon" className="" />Baby foods and instant mixes.</li>
              
        </ul>
          
        </Tab>
        <Tab eventKey="Banana2" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />Rich in fiber, antioxidants, and vitamin B6.</li>
              <li><img src={icon} alt="icon" className="" />Supports heart health, digestion, and weight management.</li>
              <li><img src={icon} alt="icon" className="" /> Acts as a natural energy booster.</li>
              <li><img src={icon} alt="icon" className="" />Contains essential nutrients for overall health.
              </li>
        </ul>
         
        </Tab>
        
      </Tabs>
      </div>
        </div>
        </Col>
        </Row>
        </div>
        </Col>

        <Col md={6} >
          <div className="mi-gray">
            <div className="d-flex flex-column infor-di mb-3">
              <h2 className="title font-22">Dehydrated Beetroot
              </h2>
            </div>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={4}>
            <img src={require('../assets/img/product/p7.jpg')} alt="product" className='img-fluid' />
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={8}>
            <div className="d-flex flex-column infor-di">
             <p>
             Dehydrated beetroot is processed into flakes and powder using advanced technology to retain its
natural taste, quality, and nutritional value. It is appreciated for its versatility and health benefits.

              </p>
              
              </div>
            </Col>
          </Row>
          <Row>
          <Col md={12} sm={12} className="mt-3">
          <div className="d-flex flex-column infor-di">
          <div className="tab-section">
        <Tabs defaultActiveKey="beetroot" id="product-tabs" className="mb-3 ">
        <Tab eventKey="beetroot" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />High in vitamins, minerals, and dietary nitrates.</li>
                <li><img src={icon} alt="icon" className="" />Helps lower blood pressure, boost muscle performance, and maintain healthy weight.</li>
                <li><img src={icon} alt="icon" className="" />Aids in digestion and supports proper nerve and muscle function.</li>
          </ul>
           
          </Tab>
          <Tab eventKey="beetroot1" title="Key Features" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" />Rich in natural sugars and essential nutrients.</li>
                <li><img src={icon} alt="icon" className="" />Offers multiple health benefits and medicinal properties.
                </li>
                <li><img src={icon} alt="icon" className="" />Free from artificial additives.</li>
          </ul>
            
          </Tab>
          <Tab eventKey="beetroot2" title="Applications" className="mb-3 ">
          <ul className="listname">
                <li>
                <img src={icon} alt="icon" className="" /><p><strong>Salads: </strong> Adds color, flavor, and nutrition</p></li>
                <li><img src={icon} alt="icon" className="" /><p> <strong>Seasonings:</strong> Used in vegetable juices, chips, and flavoring mixtures.</p></li>
                <li><img src={icon} alt="icon" className="" /> <p><strong>Medicinal Uses:</strong> Helps address rheumatic complaints and supports overall health.</p></li>
          </ul>
           
          </Tab>
          
        </Tabs>
        </div>
          </div>
          </Col>
          </Row>
          </div>
          </Col>
          
        <Col md={6} >
        <div className="mi-gray">
          <div className="d-flex flex-column infor-di mb-3">
            <h2 className="title font-22">Spray-Dried Pomegranate Powder
            </h2>
          </div>
        <Row className="align-items-center ">
          {/* Column 1: Image */}
          <Col lg={4}>
          <img src={require('../assets/img/product/f3.jpg')} alt="product" className='img-fluid' />
          </Col>

          {/* Column 2: Information */}
          <Col lg={8}>
          <div className="d-flex flex-column infor-di">
           <p>
           Our pomegranate powder retains the sweet, tangy flavor and high nutritional value of fresh
pomegranates. It serves as a natural flavoring and coloring agent for a variety of applications.

            </p>
            
            </div>
          </Col>
        </Row>
        <Row>
        <Col md={12} sm={12} className="mt-3">
        <div className="d-flex flex-column infor-di">
        <div className="tab-section">
      <Tabs defaultActiveKey="Pomegranate" id="product-tabs" className="mb-3 ">
      <Tab eventKey="Pomegranate" title="Health Benefits" className="mb-3 ">
      <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />Packed with antioxidants that purify blood and support heart health.</li>
              <li><img src={icon} alt="icon" className="" />Rich in vitamins and minerals, including potassium and magnesium.</li>
              <li><img src={icon} alt="icon" className="" />Boosts immunity and improves digestion.</li>
              <li><img src={icon} alt="icon" className="" />Enhances the appearance and texture of foods and drinks.</li>
        </ul>
         
        </Tab>
        <Tab eventKey="Pomegranate2" title="Applications" className="mb-3 ">
        <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />Protein and energy drinks.</li>
              <li><img src={icon} alt="icon" className="" />Plant-based foods and health supplements.</li>
              <li><img src={icon} alt="icon" className="" /> Instant mixes and nutraceuticals.</li>
              <li><img src={icon} alt="icon" className="" /> Beverages, desserts, and gym drinks.</li>
        </ul>
         
        </Tab>
        
      </Tabs>
      </div>
        </div>
        </Col>
        </Row>
        </div>
        </Col>

        <Col md={6} >
        <div className="mi-gray">
          <div className="d-flex flex-column infor-di mb-3">
            <h2 className="title font-22">Spray-Dried Strawberry Powder
            </h2>
          </div>
        <Row className="align-items-center ">
          {/* Column 1: Image */}
          <Col lg={4}>
          <img src={require('../assets/img/product/f10.jpg')} alt="product" className='img-fluid' />
          </Col>

          {/* Column 2: Information */}
          <Col lg={8}>
          <div className="d-flex flex-column infor-di">
           <p>
           Strawberry powder is a natural sweetener and flavor enhancer, capturing the vibrant taste and
aroma of fresh strawberries. It is versatile, clump-free, and easily soluble.

            </p>
            
            </div>
          </Col>
        </Row>
        <Row>
        <Col md={12} sm={12} className="mt-3">
        <div className="d-flex flex-column infor-di">
        <div className="tab-section">
      <Tabs defaultActiveKey="Strawberry" id="product-tabs" className="mb-3 ">
      <Tab eventKey="Strawberry" title="Applications" className="mb-3 ">
      <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />Beverages, yogurts, and bakery products.</li>
              <li><img src={icon} alt="icon" className="" />Baby foods and health supplements.</li>
              <li><img src={icon} alt="icon" className="" />Nutraceuticals and energy bars.</li>
              <li><img src={icon} alt="icon" className="" />Ice creams, jams, and desserts.</li>
        </ul>
         
        </Tab>
      
        <Tab eventKey="Strawberry2" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />High in vitamin C, manganese, and potassium</li>
              <li><img src={icon} alt="icon" className="" />Supports heart health and reduces blood sugar levels.</li>
              <li><img src={icon} alt="icon" className="" /> Contains antioxidants that help prevent cancer.</li>
              <li><img src={icon} alt="icon" className="" /> Promotes weight management and digestive health.</li>
        </ul>
         
        </Tab>
      </Tabs>
      </div>
        </div>
        </Col>
        </Row>
        </div>
        </Col>

        <Col md={6} >
        <div className="mi-gray">
          <div className="d-flex flex-column infor-di mb-3">
            <h2 className="title font-22">Spray-Dried Orange Powder
            </h2>
          </div>
        <Row className="align-items-center ">
          {/* Column 1: Image */}
          <Col lg={4}>
          <img src={require('../assets/img/product/f6.jpg')} alt="product" className='img-fluid' />
          </Col>

          {/* Column 2: Information */}
          <Col lg={8}>
          <div className="d-flex flex-column infor-di">
           <p>
           Orange powder is a natural ingredient rich in vitamin C and antioxidants. It is widely used in
food, beverages, and even cosmetics.

            </p>
            
            </div>
          </Col>
        </Row>
        <Row>
        <Col md={12} sm={12} className="mt-3">
        <div className="d-flex flex-column infor-di">
        <div className="tab-section">
      <Tabs defaultActiveKey="beetroot" id="product-tabs" className="mb-3 ">
      <Tab eventKey="beetroot" title="Applications" className="mb-3 ">
      <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />Flavored beverages, dairy products, and confectionery.</li>
              <li><img src={icon} alt="icon" className="" />Health mixes and supplements.</li>
              <li><img src={icon} alt="icon" className="" />Ayurvedic products and cosmetics for skin health.
              </li>
        </ul>
         
        </Tab>
        <Tab eventKey="beetroot1" title="Health Benefits" className="mb-3 ">
        <ul className="listname">
              <li>
              <img src={icon} alt="icon" className="" />High in vitamin C, promoting immunity and skin health.</li>
              <li><img src={icon} alt="icon" className="" />Rich in fiber, aiding digestion and weight management.
              </li>
              <li><img src={icon} alt="icon" className="" />Contains phytonutrients for heart health and disease prevention.</li>
              <li><img src={icon} alt="icon" className="" />Natural citric acid helps prevent kidney stones.</li>
        </ul>
          
        </Tab>
       
        
      </Tabs>
      </div>
        </div>
        </Col>
        </Row>
        </div>
        </Col>

       

          </Row>
        
      </Container>
    </section>
    <section className="product-tab section-padding">
    <Container>
      <Row>
        <Col lg={6} sm={12} className="mx-auto">
          <h3>We invite you to join us on our journey toward a brighter, healthier future.</h3>
        </Col>
        
      </Row>
    </Container>
  </section>
  </div>
  );
}

export default DriedFruit;
