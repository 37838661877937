import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import BlogCard from '../components/BlogCard';
import TestimonialCarousel from '../components/TestimonialCarousel';
import ButtonLink from '../components/ButtonLink';
import TabSection from '../components/ProductTab';
import HeroSection from '../components/HeroSlide';
import icon from '../assets/img/check.svg';
import icon1 from '../assets/img/icon-1.svg';
import icon2 from '../assets/img/icon-2.svg';


function Home() {
    return (
        <div className="homepage">
        <section className="hero-section">
            <Container className="my-5">
                <Row className="justify-content-between align-items-center">
                <Col md={6} sm={12}>
                  <div className="hero-info">
                    <h1>Renowned <span>Suppliers & Exporters</span> of Agri Products.</h1>
                    <p>we specialize in delivering products that combine nutrition, taste, and reliability to a global market
                    </p>
                    <ButtonLink link="/product" name="Read more" />
                  </div>
                </Col>
                <Col md={5} sm={12}>
                  <div className="hero-slider">
                    <HeroSection />
                  </div>
                </Col>
                </Row>
            </Container>
        </section>

        <section className="welcome-message section-padding">
        <Container>
          <Row className="align-items-center ">
            {/* Column 1: Image */}
            <Col lg={6} sm={12}>
            <div className="welcome-img">
              <img src={require('../assets/img/welcome-ing.jpg')} alt="product" className='img-fluid rounded' />
              </div>
            </Col>
  
            {/* Column 2: Information */}
            <Col lg={6} sm={12}>
            <div className="d-flex flex-column infor-di">
              <h4 className="subtitle">Our Introductions</h4>
              <h2 className="title">Pure and Sustainable 
              Organic Farming Products</h2>
              <p>
              At Sunrise Agri Products, we believe in transforming the natural bounty of fruits and vegetables into convenient, high-quality dehydrated forms that cater to the needs of modern consumers. From granules to powders to flakes, we specialize in delivering products that combine nutrition, taste, and reliability to a global market.
              </p>
              <div className="welcome-list">
                  <div>
                  <img src={icon1} alt="icon" className="" />
                  <span>Ready-to-eat food products</span>
                  </div>
                  <div>
                  <img src={icon2} alt="icon" className="" />
                  <span>Medicinal uses </span>
                  </div>
              </div>
              <ul className="listname">
                <li><img src={icon} alt="icon" className="" />Soups and sauces</li>
                <li><img src={icon} alt="icon" className="" />Fast food and stuffing mixes </li>
              </ul>
              <ButtonLink link="/about" name="Read more" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="product-tab section-padding">
        <Container>
          <Row>
            <Col lg={6} sm={12} className="mx-auto">
              <h3>Be Healthy & Eat Only Fresh Sunrise Agri Product</h3>
            </Col>
            
          </Row>
        </Container>
      </section>
      <section className="tab-information section-padding pt-0">
        <Container>
          <Row>

            <Col md={12} sm={12}>
            <TabSection />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog-wrapper section-padding">
        <Container>
            <Row>
                <Col lg={6} sm={12}>
                <div className="d-flex flex-column infor-di">
                  <h4 className="subtitle">Client Testimonials
                  </h4>
                  <h2 className="title">Trusted by Many,
                  Loved by All</h2>
                  <p>
                  Here's what our clients are saying about us! Their words inspire us to do even better every day.
                  </p>
                </div>
                </Col>
                <Col lg={6} sm={12}>
                <TestimonialCarousel />
                </Col>
            </Row>
        </Container>
     </section>
      <section className="py-5">
        <Container>
            <Row>
                <Col>
                <div className="d-flex flex-column infor-di text-center">
                  <h4 className="subtitle">Client Testimonials
                  </h4>
                  <h2 className="text-center title">Our Latest Blog Posts</h2>
                  </div>
                    
                </Col>
                <Col sm={12}>
                    <BlogCard />
                    </Col>
            </Row>
        </Container>
     </section>
     </div>
    );
}

export default Home;
