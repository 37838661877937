import React from 'react';
import { Tabs, Tab, Row, Col } from 'react-bootstrap';

const TabSection = () => {
  return (
    
    <div className="tab-section">
        <Tabs defaultActiveKey="dehydrated" id="feature-tabs" className="mb-3 text-center">
          <Tab eventKey="dehydrated" title="Dehydrated vegetables" className="mb-3 text-center">
          
            <Row className='justify-content-center'>
                <Col md={12}>
                <div className="d-flex flex-column infor-di text-center mb-5">
                  <h4 className="subtitle">Our product portfolio includes high-quality dehydrated vegetables such as
                  </h4>
                  <h2 className="text-center title">Dehydrated Vegitables We Offer
                  </h2>
                  </div>
                </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
                <div className='product-box'>
                    <img src={require('../assets/img/product/p3.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated 
                    Garlic</h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p5.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated 
                    Onion</h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p4.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Mixed Veg
                    Dehydrated</h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p6.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated
                    Ginger </h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p7.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated
                     Beetroot </h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p8.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated
                    Potato </h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p9.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated
                    Carrot </h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p10.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated
                    Tomato</h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p11.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated
                    Cabbage</h4>
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/p12.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Dehydrated
                    Bitter Gourd </h4>
                    </div>
                </div>
            </Col>
            
           
            
            </Row>
          </Tab>
          <Tab eventKey="feature2" title="Spray Dried Fruit powder" className="mb-3 text-center">
          
            <Row className='justify-content-center'>
            <Col md={12}>
                <div className="d-flex flex-column infor-di text-center mb-5">
                  <h4 className="subtitle">Our range of high-quality dehydrated fruits includes:
                  </h4>
                  <h2 className="text-center title">Dehydrated Fruits We Offer
                  </h2>
                  </div>
                </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
                <div className='product-box'>
                    <img src={require('../assets/img/product/p1.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Mango</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f2.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Apple</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f3.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Pomegranate</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f4.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Banana</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f5.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Grapes</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f6.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Oranges</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f7.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Papaya</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f8.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Pineapple</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f9.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Custard Apple</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f10.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Strawberry</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f11.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Coconut</h4>
                    
                    </div>
                </div>
            </Col>
            <Col xl={2} md={3} sm={4} xs={6} className="mb-3">
            <div className='product-box'>
                    <img src={require('../assets/img/product/f12.jpg')} alt="product" className='img-fluid' />
                    <div className='content'>
                    <h4>Watermelon</h4>
                    
                    </div>
                </div>
            </Col>
            </Row>
          </Tab>
        </Tabs>
    </div>
  );
};

export default TabSection;
