import React from 'react';
import Slider from 'react-slick';
import '../assets/css/TestimonialCarousel.css'; // Optional: For custom styles
import 'slick-carousel/slick/slick.css';

import 'slick-carousel/slick/slick-theme.css';



const TestimonialCarousel = () => {
  const settings = {
    infinite: true,  // Enables infinite looping
    speed: 500,      // Transition speed (ms)
    slidesToShow: 2, // Number of slides to show at once
    slidesToScroll: 2, // Number of slides to scroll at once
    autoplay: true,  // Automatically slide at a regular interval
    autoplaySpeed: 3000, // Interval for autoplay (in ms)
    dots: false,      // Show navigation dots
    arrows: true,    // Show next and previous arrows
    centerMode: false, // Center the active slide
    focusOnSelect: false, // Focus on the selected slide
    responsive: [
      {
        breakpoint: 768,  // On smaller screens, show only 1 item
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="testimonial-section py-5">
      <Slider {...settings}>
        <div className="testimonial-item">
          <p className="testimonial-text">"I’ve tried many spicy products, but this one takes the cake! The perfect balance of heat and flavor. Highly recommend for all spice lovers</p>
          <div className="testi-inform">
          {/* <img src="https://via.placeholder.com/100" alt="Client 1" className="" /> */}
          <div className='client-info'>
            <h5 className="client-name">Bhushan Bhavsar</h5>
            <p className="client-role">Founder</p>
          </div>
          </div>
        </div>
        <div className="testimonial-item">
          <p className="testimonial-text">Absolutely loved it! The heat is intense but doesn't overpower the incredible flavor. Will definitely buy again!</p>
          <div className="testi-inform">
          {/* <img src="https://via.placeholder.com/100" alt="Client 1" className="" /> */}
          <div className='client-info'>
            <h5 className="client-name">Amol Kate</h5>
            <p className="client-role">Founder</p>
          </div>
          </div>
        </div>
        <div className="testimonial-item">
          <p className="testimonial-text">"The spice level is perfect—not too mild, not too overwhelming. It’s my new go-to for adding a burst of flavor to my meals!</p>
          <div className="testi-inform">
          {/* <img src="https://via.placeholder.com/100" alt="Client 1" className="" /> */}
          <div className='client-info'>
            <h5 className="client-name">Narest Thete</h5>
            <p className="client-role">CEO</p>
          </div>
          </div>
        </div>
        <div className="testimonial-item">
          <p className="testimonial-text">I  ordered Mango powder from you to put into my restaurant for a fall treat and I have to say that it is awesome.  Great color and taste. </p>
          <div className="testi-inform">
          {/* <img src="https://via.placeholder.com/100" alt="Client 1" className="" /> */}
          <div className='client-info'>
            <h5 className="client-name">John Doe</h5>
            <p className="client-role">CEO, Company X</p>
          </div>
          </div>
        </div>
       
        {/* Add more testimonial items here */}
      </Slider>
    </section>
  );
};

export default TestimonialCarousel;
